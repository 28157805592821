.container {
  width: 100vw;
  height: auto;
}
.Todo-list-parent {
  width: 70%;
  height: auto;
  margin: 5% auto;
}
@media (max-width: 500px) {
  .container {
    width: 100%;
  }
  .Todo-list-parent {
    width: 100%;
  }
}
