.Search-panel-input {
  margin: 1.5rem 0;
  width: 60%;
}

@media (max-width: 500px) {
  .Search-panel-input {
    margin: 1.5rem 0 1rem 0;
  }
}
