.todo-list-item {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 1.7rem;
}
.todo-list-item-active {
  color: #15d3d3;
  font-size: 1.7rem;
  font-weight: 600;
}
.done {
  text-decoration: line-through;
}
