.Item-add-form-parent {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin: 1rem 0;
}
.input {
  height: 3rem;
  margin-right: 2rem;
}
.button {
  width: 5rem;
  height: 3rem;
}
