.Panel-parent {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 500px) {
  .Panel-parent {
    display: block;
    margin-bottom: 1rem;
  }
}
