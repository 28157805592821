.Filter-parent {
  width: 40%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}
/* .Filter-btn#first-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.Filter-btn#second-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.Filter-btn#third-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
} */
.Filter-btn:nth-child(2) {
  margin: 0 0.25rem;
}
@media (max-width: 500px) {
  .Filter-parent {
    justify-content: flex-start;
  }
}
