.todo-list {
  width: 100%;
  height: auto;
}
.todo-list-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.65rem 0.75rem;
  margin: 0rem 0;
}
.todo-list-li button {
  margin-right: 0.5rem;
}
@media (max-width: 400px) {
  .todo-list-li {
    display: block;
  }
}
